import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

//Styled Components
import { createGlobalStyle, ThemeProvider } from "styled-components";
import Navigation from "./layout/Navigation";
import Footer from "./layout/Footer";

// import "fontsource-montserrat"; // Defaults to weight 400 with all styles included.
// import Navigation from "./layout/Navigation";
// import Footer from "./layout/Footer";

//Components

// import {
//     useGlobalStateContext,
//     useGlobalDispatchContext,
// } from "../context/globalContext";

const GlobalStyle = createGlobalStyle`
* {
  text-decoration: none;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 62.5%;
    scroll-behavior: smooth;
    }
body {
  font-family: "Questrial", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}   
ul {
    list-style-type: none;
}
p {
    font-size: 1.6rem;
    line-height: 2.5rem;
    padding: 6px 0;
}
h2 {
    font-size: 5rem;
}
h3 {
    font-size: 2.2rem;
}
h4 {
        font-size: 2rem;
    }
section {

}
`;

const Layout = ({ children }) => {
    // const data = useStaticQuery(graphql`
    //     query SiteTitleQuery {
    //         site {
    //             siteMetadata {
    //                 title
    //             }
    //         }
    //     }
    // `);
    // const darkTheme = {};
    const lightTheme = {
        primary: "#1be7ff",
        secondary: "#EBD749",
        highlight: "#82F3FF",
        // secondary: "#fd5b78",
        // text: "#504f54",
        // textLight: "#a1a1a1",
        // textDark: "#4a4a4a",
        textDark: "#283341",
        textHover: "#41546c",
        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
        background: "#F7FAFB",
        background2: "#F0EDE8",
        background3: "#3A3A3A",
        color: {
            ultralight: "204, 234, 231",
            // light: "155, 208, 225",
            primary: "62, 214, 198",
            secondary: "253, 91, 120",
            medium: "159, 202, 229",
            dark: "131, 176, 214",
            ultradark: "20, 45, 71",
            blue: "116, 173, 222",
        },
    };

    return (
        <ThemeProvider theme={lightTheme}>
            <GlobalStyle />
            {/* <Navigation />
            <main>{children}</main>
            <Footer /> */}
            <Navigation />
            <main>{children}</main>
            <Footer />
        </ThemeProvider>
    );
};

export default Layout;

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
