import React from "react";
import styled from "styled-components";
import { device } from "../../util/device";

const StyledFooter = styled.div`
    height: 100px;
    width: 100%;
    /* background-color: red; */
    display: grid;
    grid-template-columns: auto 1fr;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: ${(props) => (props.center ? "center" : "left")};
    padding: 50px 100px;
    @media ${device.small} {
        padding: 20px;
    }
    @media ${device.extraSmall} {
        padding: 10px;
        p {
            font-size: 1.4rem;
            line-height: 1.3;
        }
    }
`;

const Footer = () => {
    return (
        <StyledFooter>
            <Content center>
                <p>
                    1234 Alphabet Road, VA 20123
                    <br />
                    (407) 422-0169
                </p>
            </Content>
            <Content>
                <h3>FROM US TO YOU</h3>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat?
                    Look no further, call today!
                    <br />
                    <span style={{ fontWeight: "bold" }}>(123) 456-7890</span>
                </p>
            </Content>
        </StyledFooter>
    );
};

export default Footer;
