import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Home from "./home";

// styles

// markup
const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Questrial:wght@500;600;&family=Poppins:wght@500;600;700&display=swap"
                    rel="stylesheet"
                />
                <script
                    src="https://kit.fontawesome.com/03bb7a5413.js"
                    crossorigin="anonymous"
                ></script>
            </Helmet>
            <Home />
        </Layout>
    );
};

export default IndexPage;
