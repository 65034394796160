import React from "react";
import styled from "styled-components";
import { Container } from "../../styles/globalStyles";

import logoIcon from "../../assets/images/logo.png";
import { motion } from "framer-motion";
import { device } from "../../util/device";

const StyledNavigation = styled(motion.nav)`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    /* padding: 20px 0; */
    z-index: 999;
    padding: 20px 150px;
    @media ${device.small} {
        padding: 20px;
    }
    @media ${device.extraSmall} {
        padding: 5px;
    }
`;

const Navbar = styled.div`
    color: ${(props) => props.theme.textDark};
    background-color: #fff;
    font-family: "Questrial";
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    margin-left: 5px;
    h3 {
        font-weight: 500;
        font-size: 2rem;
        @media ${device.small} {
            font-size: 1.8rem;
        }
        @media ${device.extraSmall} {
            display: none;
        }
    }
`;

const LogoIMG = styled.img`
    width: 70px;
`;

const Menu = styled.ul`
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
`;

const MenuItems = styled.li`
    display: flex;
    align-items: center;
    font-weight: 600;
    a {
        font-size: 1.5rem;
        letter-spacing: 0.1px;
        padding: 20px 20px;
        color: ${(props) => props.theme.textDark};
        @media ${device.small} {
            font-size: 1.4rem;
            padding: 5px 5px;
        }

        &:hover {
            opacity: 0.7;
        }
    }
`;

const Button = styled.button`
    font-weight: 600;
    border: none;
    background-color: ${(props) => props.theme.textDark};
    color: #fff;
    padding: 15px 12px;
    margin: 0 15px;
    border-radius: 4px;
    cursor: pointer;
    @media ${device.small} {
        padding: 8px 6px;
        margin: 0 5px;
    }
`;

const Navigation = () => {
    return (
        <StyledNavigation
            initial={{ opacity: 0, y: -100, scale: 1.02 }}
            animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: { duration: 0.4, ease: "circOut" },
            }}
        >
            <Navbar>
                <Logo>
                    <LogoIMG src={logoIcon} href="#hero" />
                    <h3>Mako Flooring</h3>
                </Logo>
                <Menu>
                    <MenuItems>
                        <a href="#about">About Us</a>
                    </MenuItems>
                    <MenuItems>
                        <a href="#product">Products</a>
                    </MenuItems>
                    <MenuItems>
                        <a href="#why-us">Our Services</a>
                    </MenuItems>
                    <MenuItems>
                        <a href="#contact">Contact</a>
                    </MenuItems>
                    <Button>FREE ESTIMATE</Button>
                </Menu>
            </Navbar>
        </StyledNavigation>
    );
};

export default Navigation;
